import React from "react";
import CustomizerComponent from "@/bit/components/components.customizer-component";
import Card from "@/home_components/card";
import Title from "./title";

const FeaturedOffer = ({
  title,
  offerProps,
  offerActivities,
  titleActivity,
}) => {
  return (
    <section className="featuredOfferCards" data-cy="Section offer cards">
      {title && (
        <CustomizerComponent
          name={titleActivity}
          Component={Title}
          componentProps={title}
          componentName="OfferTitle"
        />
      )}
      <div className="container featuredOfferCards--content">
        <div className="row">
          {offerProps &&
            Array.isArray(offerProps) &&
            offerProps.map((offer, i) => {
              return (
                <div
                  className="col-xs-12 col-sm-6 col-md-3 col-lg-3 featuredOfferCards--content_cards"
                  key={`card-${i}`}
                >
                  <CustomizerComponent
                    name={offerActivities[i]}
                    Component={Card}
                    componentProps={offer}
                    componentName={`OfferCard${i + 1}`}
                    skeletonClassName="skeleton-banner-card"
                  />
                </div>
              );
            })}
        </div>
      </div>
      <style jsx>
        {`
          .featuredOfferCards {
            padding: 1rem 0;
            background-color: #ecebeb;
            justify-content: center;
            flex-direction: column;
            line-height: 29px;
            :global(h1) {
              letter-spacing: -0.5px;
              padding-bottom: 1rem;
            }

            :global(.card.layout-product .card-container--label) {
              min-height: auto;
            }
            :global(.card) {
              padding: 0;
              :global(.card-container) {
                gap: 0;
              }
              :global(.card-container--image .custom-image) {
                border-radius: 0;
              }

              :global(.card-container--info) {
                padding: 1.5rem;
                gap: 1rem;
                :global(.card-container--label) {
                }
              }
              :global(.card-container--image) {
                max-width: none;
                aspect-ratio: unset;
              }
              :global(img) {
                object-fit: cover !important;
              }
            }
            &--content {
              line-height: normal;
              &_cards {
                position: relative;
                display: flex;
                flex-wrap: wrap;
              }
              .row {
                grid-row-gap: 0.75rem;
                align-items: stretch;
              }
            }
          }
          @media screen and (max-width: 767px) {
            .featuredOfferCards {
              :global(.card-column .capta-price--mobile) {
                display: none;
              }

              :global(.card-container--info) {
                width: 65%;
              }
              :global(.card-container--image--stamp) {
                width: 35%;
                height:auto;
              }
              :global(.card-container--image) {
               height:100%;
              }
              :global(.card-column .layout-tariff .card-container--info) {
                width: 100%;
              }


            }
          }
          @media screen and (min-width: 768px) {
            .featuredOfferCards {
              :global(.card) {
                :global(.card-container) {
                  gap: 0;
                }
                :global(.card-container--image) {
                  padding-top: 75%;
                }
                :global(img) {
                  object-fit: cover !important;
                }
              }
              &--content {
                padding-top: 1rem;
                padding-bottom: 1rem;
                .row {
                  grid-row-gap: 1rem;
                }
              }
              :global(.card.layout-product .card-container--label) {
                min-height: 2rem;
              }
              :global(.card .layout-tariff) {
                :global(.card-container--info) {
                  :global(.card-container--label) {
                    min-height: auto;
                  }
                }
              }
            }
          }
          @media screen and (min-width: 1280px) {
            .featuredOfferCards {
              padding-top: 1.5rem;
              &--content {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                .row {
                  grid-row-gap: 1.5rem;
                }
              }
            }
          }
        `}
      </style>
    </section>
  );
};

export default FeaturedOffer;
