import React from "react";
import Card from "@/home_components/card";



const createAhorroLabel = (cardData) => {
  const res = cardData;
  const { originalPrice, monthlyPayment } = cardData;
  const r2rPrice = cardData["Price-2"]?.amount;
  
  if (r2rPrice && originalPrice && monthlyPayment) {
    const ahorro = -(
      parseFloat(monthlyPayment) * parseFloat(r2rPrice) -
      parseFloat(originalPrice) +
      1
    );
    if (ahorro > 10) {
      const normalizedSavingForLabel = Math.round(ahorro);
      res["Label-2"] = {
        label: `Ahorro ${normalizedSavingForLabel}€`,
        theme: "discount",
        inversed: false,
        notBorderRadius: true,
      };
    }
  }
  return res;
};

const DevicesOffers = ({ cardData, taggingInfo }) => {
  const labelAhorro = createAhorroLabel(cardData);
  const {custoStamps} = cardData;
  return (
    <>
      <div className="featured-dispo-card">
        <Card
          taggingInfo={taggingInfo}
          {...{ ...cardData, ...labelAhorro, custoStamps: custoStamps }}
          imageClassName="aspect-ratio-4-5"
        ></Card>
      </div>

      <style jsx>
        {`
          .featured-dispo-card {
            height: 100%;

            :global(.card-column) {              
              min-height: 200px;
              width: 11.9rem;
            }

            :global(.card) {
              padding: 1rem;
            }

            :global(.card-clickable) {
              height:100%;
              align-items:flex-end;
            }
            :global(.card-container) {
              gap: 0.7rem;
              align-items: flex-end;
              flex-flow: column nowrap;
              flex: 1;
              height:100%;
              :global(.card-container--info) {
                justify-content:flex-end;                
              }
              :global(.label) {
                padding: 0.25rem 0.5rem;
              }

              :global(.card-container--image--stamp) {
                height:11.2rem;
                gap:0.1rem;
              }
              :global(.card-container--image) {
                max-width: 8.25rem;
                height:100%;
              }

              :global(.card-container--image.with-stamp) {                
                height:9rem;
              }
              :global(.card-content--price) {
                line-height: 1.25rem;
                margin-top: 0.2rem;
                :global(.price) {
                  display: inline-block;
                  margin-right: 0.2rem;
                }

                :global(.price--pre-price) {
                  display: block;
                }
              }

              :global(.card-header) {
                
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  min-height: 3rem;

                :global(.card-header--title) {
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }
              :global(.card-container--info) {
                gap: 0.7rem;
                flex: 1;
              }
            }
          }

          @media screen and (min-width: 768px) {
            .featured-dispo-card {
              :global(.card-column) {
                height: 100%;
                width: inherit;

                :global(.card-header--title) {
                  line-height: 1.5rem;
                }
              }

              :global(.card-container) {
                flex-flow: row nowrap;
                align-items: flex-end;
                flex: inherit;
               
              :global(.card-container--info) {
                padding-bottom:0.5rem;
              }

                :global(.card-container--image) {
                  max-width: unset;
                }

                :global(.card-header) {
                  min-height: inherit;

                  :global(.text) {
                    line-height: 1.5rem;
                  }
                }

                :global(.card-content) {
                  height:auto;
              }
              }
            }
          }

          @media screen and (min-width: 1280px) {
            .featured-dispo-card {
              :global(.card-column) {
                min-width: 0;
                height: 100%;
              }

              :global(.card) {
                :global(.offer-progress) {
                  display: block;

                  :global(.offer--text) {
                    display: flex;
                    justify-content: space-between;
                  }
                }
              }

              :global(.card-container) {
                :global(.card-container--label) {
                  display: block;
                  width: 100%;
                }

                :global(.card-content--price) {
                  :global(.price--pre-price) {
                  }
                }

                :global(.card-header) {
                  :global(.text) {
                    line-height: 1.5rem;
                  }
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default DevicesOffers;
